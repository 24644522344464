<template>
  <div
    class="overflow-y-scroll overflow-x-hidden relative scroll-hidden"
    :style="{ height: height + 'px' }"
    @scroll="scroll"
  >
    <a-row :gutter="20">
      <a-col
        :span="24 / size"
        v-for="(dataSource, index) in list"
        :key="dataSource.ID"
      >
        <slot v-bind="{ dataSource: dataSource }"></slot>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },
    gutter: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 100,
    },
    status: {
      type: String,
    },
  },
  methods: {
    scroll(e) {
      const target = e.target,
        isBottom =
          target.scrollHeight - target.clientHeight - target.scrollTop < 50,
        isToBottom = target.scrollTop > +this.old;

      if (isBottom && isToBottom && !this.stop) {
        this.stop = true;
        this.$emit("scrollBottom");
        setTimeout(() => {
          this.stop = false;
        }, 500);
      }
      this.old = target.scrollTop;
    },
  },
};
</script>