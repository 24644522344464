<template>
  <a-tabs @change="change">
    <a-tab-pane key="1" tab="全部">
      <ScrollList
        :list="message"
        :height="700"
        :status="status1"
        @scrollBottom="scrollBottom1"
      >
        <MessageCard
          slot-scope="{ dataSource }"
          :dataSource="dataSource"
          @click.native="read(dataSource)"
        ></MessageCard>
      </ScrollList>
      <!-- <transition
        name="custom-classes-transition"
        mode="out-in"
        enter-active-class="animate__animated animate__slideInUp"
        leave-active-class="animate__animated animate__slideOutDown"
      >
        <div
          class="
            absolute
            bottom-0
            w-full
            h-40
            text-center
            leading-10
            bg-blue-200
          "
          v-if="status1 === 'loading'"
        >
          <span v-if="status1 === 'loading'" class="inline-flex items-center"
            ><a-icon class="mr-10" class="loading" />加载中</span
          >
        </div>
      </transition> -->
    </a-tab-pane>
    <a-tab-pane key="2" tab="未读">
      <ScrollList
        :list="unmessage"
        :height="700"
        :status="status2"
        @scrollBottom="scrollBottom2"
      >
        <MessageCard
          slot-scope="{ dataSource }"
          :dataSource="dataSource"
          @click.native="read(dataSource)"
        ></MessageCard>
      </ScrollList>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import ScrollList from "@/components1/ScrollList/index.vue";
import MessageCard from "@/components1/message/index.vue";
import { getMessageList, updateMessageState } from "@/api/api/message";
export default {
  components: { ScrollList, MessageCard },
  computed: {
    id() {
      return this.$store.state.user.USER_INFO.id;
    },
  },
  data() {
    return {
      message: [],
      unmessage: [],
      count: 0,
      uncount: 0,
      active: "1",
      offset1: 10,
      offset2: 10,
      status1: "",
      status2: "",
      class: 2,
    };
  },
  created() {
    this.change(this.active);
  },
  methods: {
    change(e) {
      this.active = e;
      switch (e) {
        case "1":
          this.offset1 = 10
          this.getMessageList(this.id);
          break;
        case "2":
          this.offset2 = 10
          this.getUnMessageList(this.id);
          break;
      }
    },
    getMessageList(id) {
      getMessageList(id, { limit: 10, offset: 0, class: this.class })
        .then(({ data }) => {
          this.message = data.rows || [];
          this.count = data.count;
        })
        .catch(() => {});
    },
    getUnMessageList(id) {
      getMessageList(id, {
        unread: true,
        limit: 10,
        offset: 0,
        class: this.class,
      })
        .then(({ data }) => {
          this.unmessage = data.rows || [];
          this.uncount = data.count;
        })
        .catch(() => {});
    },
    read(data) {
      if (!data.Read) {
        updateMessageState(data.ID)
          .then(() => {
            this.change(this.active);
            this.$store.dispatch("message/getMessage");
          })
          .catch(() => {
            this.$message.error("读取信息失败");
          });
      }
    },
    scrollBottom1() {
      this.status1 = "loading";
      getMessageList(this.id, {
        limit: 10,
        offset: this.offset1,
        class: this.class,
      })
        .then(({ data }) => {
          const rows = data.rows || [];
          this.message.push(...rows);
          if (rows.length) this.offset1 += 10;
        })
        .catch(() => {})
        .finally(() => {
          this.status1 = "finish";
        });
    },
    scrollBottom2() {
      this.status2 = "loading";
      getMessageList(this.id, {
        limit: 10,
        offset: this.offset2,
        class: this.class,
      })
        .then(({ data }) => {
          const rows = data.rows || [];
          is.unmessage.push(...rows);
          if (rows.length) this.offset2 += 10;
        })
        .catch(() => {})
        .finally(() => {
          this.status2 = "finish";
        });
    },
  },
};
</script>