<template>
  <div>
    <slot></slot>
    <div class="text-right">
      <a-space>
        <a-popover trigger="click">
          <a-button type="link">查看二维码</a-button>
          <div slot="content" style="width: 200px">
            <img class="object-contain" :src="meta.qrcode" alt="" />
          </div>
        </a-popover>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>