<template>
  <div class="text-sm relative p-20 border-b border-eee">
    <div
      v-if="!dataSource.Read"
      class="absolute left-0 top-20 w-10 h-10 rounded-full"
      style="background-color: #d93434"
    ></div>

    <CodeWrapper v-if="dataSource.Type === 2502" :meta="dataSource.Metadata">
      <div>
        <div class="flex justify-between itmes-center">
          <div class="mb-10 text-black">
            {{ dataSource.Title }}赛道
          </div>
          <div class="text-xs text-999">
            {{ dataSource.CreatedAt | formatterTime("YYYY-MM-DD HH:mm") }}
          </div>
        </div>

        <div class="text-666 min-h-40">
          {{ dataSource.Content }}
        </div>
      </div>
    </CodeWrapper>
    <div v-else>
      <div class="flex justify-between itmes-center">
        <div class="mb-10 text-black">
          {{ dataSource.Metadata.track }}赛道
        </div>
        <div class="text-xs text-999">
          {{ dataSource.CreatedAt | formatterTime("YYYY-MM-DD HH:mm") }}
        </div>
      </div>
      <div class="text-666 min-h-40">
        {{ dataSource.Content }}
      </div>
    </div>
  </div>
</template>

<script>
import CodeWrapper from "./codeWrapper.vue";
export default {
  components: { CodeWrapper },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>